import debounce from "lodash.debounce";
import {
  ChangeEventHandler,
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import "../styles.scss";
import { useNavigate } from "react-router-dom";

import { fieldSearchBarTestID } from "components/app/dataTestIDs";
import { SvgImage } from "components/shared";

import { SearchContext } from "context/SearchContext";

interface SearchBarProps {
  onSearch: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value?: string;
}

export const SearchBar = ({
  onSearch,
  disabled = false,
  value,
}: SearchBarProps) => {
  const debouncedFetchData = useMemo(() => {
    return debounce(onSearch, 1000);
  }, [onSearch]);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (typeof value === "undefined" || !element) return;

    element.value = value;
  }, [value]);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(e as any);
    }
  };

  return (
    <>
      <input
        ref={ref}
        className="searchbar__input"
        placeholder="Search"
        onKeyPress={handleKeyPress}
        onChange={debouncedFetchData}
        disabled={disabled}
      />
      <span className="absolute top-2 left-3">
        <SvgImage name="SearchIcon" width={20} height={20} />
      </span>
    </>
  );
};

interface FieldSearchBarProps {
  dataTestID?: string;
  isGlobalSearch?: boolean;
}

export const FieldSearchBar = ({
  dataTestID,
  isGlobalSearch,
}: FieldSearchBarProps) => {
  const navigate = useNavigate();
  const { setSearchTxt } = useContext(SearchContext);

  useEffect(() => {
    setSearchTxt("");
  }, []);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTxt(e.target.value);
    if (isGlobalSearch) {
      navigate("/search");
    }
  };

  return (
    <div
      className="searchbar__wrapper"
      data-testid={dataTestID || fieldSearchBarTestID}
    >
      <SearchBar onSearch={handleSearch} />
    </div>
  );
};
