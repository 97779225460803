import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { ListVehicles, LoadVehicle, StatusBarTabs } from "components/app";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ALL,
  AWAITING,
  BUYNOW,
  DEFAULT_ITEMS_PER_PAGE,
  DRAFT,
  FLOW_TYPE,
  PENDING,
  REVIEW,
  STATUS_LIST,
  StorageKeys,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";
import "./styles.scss";

import { NavigationMenuLabel } from "types";

import { InspectionService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

export const WholesaleDealershipList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.WholesaleDealershipValuationList,
    1
  );
  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const { hasPermission } = useContext(AuthContext);

  const newStatus =
    currentStatus === AWAITING.key ? PENDING.key : currentStatus;

  const canViewDirectOffer = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.VIEW
  );

  const canView =
    hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
      PermissionAction.VIEW,
      PermissionAction.VIEW_ANY,
    ]) &&
    // DIRECT OFFER
    (currentStatus !== BUYNOW.key ||
      (currentStatus === BUYNOW.key && canViewDirectOffer));

  const statusList = useMemo(
    () =>
      canViewDirectOffer
        ? STATUS_LIST.WholesaleDealership
        : STATUS_LIST.WholesaleDealership.map((option) =>
            option.key === BUYNOW.key ? { ...option, disabled: true } : option
          ),
    [canViewDirectOffer]
  );

  const { data, isInitialLoading, isError } = useListQuery(
    {
      queryKey: StorageKeys.WholesaleDealershipValuationList,
      retailed: false,
      withParams: "requester,images,valuations,owner",
      status: currentStatus !== ALL.key ? currentStatus : "",
      currentPage: currentPage,
    },
    InspectionService.getVehicleList,
    {
      enabled:
        (currentStatus === ALL.key ||
          currentStatus === AWAITING.key ||
          currentStatus === REVIEW.key ||
          currentStatus === DRAFT.key) &&
        hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
          PermissionAction.VIEW,
          PermissionAction.VIEW_ANY,
        ]),
    }
  );

  const vehicles = data?.data || [];

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (
    id: string,
    isExternal: boolean,
    requestId?: string
  ) => {
    isExternal
      ? navigate(`valuation/${id}/${requestId}/summary`)
      : navigate(`valuation/${id}/summary`);
  };

  const getContent = () => {
    if (!canView)
      return (
        <NoPermissionMessage
          viewPermissionCategory={PermissionCategory.RETAIL_INSPECTIONS}
        />
      );

    if (isInitialLoading) return <LoadVehicle />;

    if (isError)
      return (
        <div className="dashboard__content--empty">
          An error has occurred. Please, try again.
        </div>
      );

    if (!vehicles?.length)
      return (
        <div className="dashboard__content--empty">
          No Inspections Created Yet
        </div>
      );

    return (
      <ListVehicles
        vehicles={vehicles}
        onSelect={handleGoSummary}
        flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Unvalued", SECTIONS.WHOLESALE_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                {
                  title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                  route: WHOLESALE_DEALERSHIP_MENU[0].route,
                },
                {
                  title: "Unvalued",
                  route: WHOLESALE_DEALERSHIP_MENU[0].route,
                },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">Unvalued</div>
            </div>
            <div className="flex flex-row gap-5 items-center">
              {canView && (
                <Pagination
                  currentPage={currentPage}
                  pageLimit={DEFAULT_ITEMS_PER_PAGE}
                  total={data?.meta?.total || 0}
                  onNextPage={handleFetchNext}
                  onPrevPage={handleFetchPrev}
                  onIndexedPage={handleFetchSelected}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <StatusBarTabs
              currentStatus={currentStatus}
              options={statusList}
              onSelect={handleChangeStatus}
            />
          </div>
        </div>
        <div className="dashboard__content">{getContent()}</div>
        <div className="dashboard__footer">
          {canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={DEFAULT_ITEMS_PER_PAGE}
              total={data?.meta?.total || 0}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
