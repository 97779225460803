import classNames from "classnames";

import { ButtonStatus } from "components/app";
import { cardVehicleTestID } from "components/app/dataTestIDs";
import { Button, Card, SvgImage } from "components/shared";
import { HighlightText } from "components/shared/HighlightText";
import { Tooltip } from "components/Tooltip";

import { DECLINED_VALUATION, getCurrentPrice, FLOW_TYPE } from "utils";
import { PPSR_STATUSES, PPSR_SUCCESS } from "utils/ppsrStatuses";

import { IVehicleDetail } from "types";

import "../styles.scss";

export interface CardVehicleProps {
  data: IVehicleDetail;
  onClick: () => void;
  dataTestID?: string;
  isExternal?: boolean;
  showValuedByMP?: boolean;
  flow?: FLOW_TYPE;
  isDealershipSelectorVisible?: boolean;
  disabled?: boolean;
  searchText?: string;
  isValuationRequest?: boolean;
  isGlobalSearch?: boolean;
}

interface VehicleInfoProps {
  label: string;
  value?: any;
  searchText?: string;
}

const VehicleInfo = ({ label, value, searchText }: VehicleInfoProps) => {
  return (
    <span className="card-vehicle__txt--info">
      {label}:
      <b>
        <HighlightText text={value} highlight={searchText} />
      </b>
    </span>
  );
};

export const CardVehicle = ({
  data,
  onClick,
  dataTestID,
  isExternal,
  showValuedByMP,
  flow,
  isDealershipSelectorVisible,
  disabled,
  searchText,
  isValuationRequest,
  isGlobalSearch,
}: CardVehicleProps) => {
  if (!data.vehicle) {
    return (
      <Card>
        <div
          className="card-vehicle !h-36"
          data-testid={dataTestID || cardVehicleTestID}
        >
          <div className="card-vehicle__img">
            <SvgImage name="NoImageSvg" />
            <div className="card-vehicle__status">
              {(data as any).opened ? (
                <ButtonStatus
                  status="opened"
                  amount={getCurrentPrice(data)}
                  label="Link Opened"
                />
              ) : (
                <ButtonStatus
                  status={data.status.name}
                  amount={getCurrentPrice(data)}
                  label="Link Sent"
                />
              )}
            </div>
          </div>
          <div className="card-vehicle__info !flex-col justify-center px-6">
            <p className="text-txt text-sm">
              A vehicle inspection link was sent to the customer.
            </p>
            <p className="text-label text-sm">
              You can resend the same link clicking “Resend Link” or send a new
              inspection with the “Send Inspection Link” at the bottom of this
              page.
            </p>
            <Button
              type="button"
              className="!bg-white !w-52 shadow-md mt-6"
              onClick={onClick}
            >
              <span className="text-primary font-bold text-sm">
                RESEND LINK
              </span>
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  const {
    images,
    vehicle: {
      name,
      description,
      compliance: { vin, registration_plate },
    },
    status: { name: status, label: statusLabel },
    valuations,
    owner,
    requester,
    created_by,
    submitted_at,
    created_at,
    ppsr,
    updated_at,
    dealership,
    seller,
  } = data;

  const valuedByMP =
    valuations?.hub_valuation &&
    !valuations?.hub_declined &&
    !!valuations?.hub?.offer;

  const ppsrStatus = PPSR_STATUSES[ppsr?.status as keyof typeof PPSR_STATUSES];

  return (
    <Card
      onClick={!disabled ? onClick : undefined}
      className={classNames(null, {
        "opacity-50 pointer-events-none": disabled,
      })}
    >
      <div
        className="card-vehicle"
        data-testid={dataTestID || cardVehicleTestID}
      >
        <div className="card-vehicle__img">
          {images && images.length === 0 ? (
            <SvgImage name="NoImageSvg" />
          ) : (
            <img src={images?.[0].thumb} alt="Vehicle" />
          )}
          <div className="card-vehicle__status">
            <ButtonStatus
              status={status}
              amount={getCurrentPrice(data)}
              label={
                status === DECLINED_VALUATION.key
                  ? DECLINED_VALUATION.label
                  : statusLabel
              }
            />
          </div>
          {valuedByMP && showValuedByMP && (
            <div className="card-vehicle__valued">
              <SvgImage name="ValuedImg" />
            </div>
          )}
        </div>
        <div className="card-vehicle__info">
          <div className="card-vehicle__section">
            <span className="card-vehicle__txt--title">
              <Tooltip
                className="card-vehicle__txt--title--name"
                tooltip={name}
              >
                <HighlightText text={name} highlight={searchText} />
              </Tooltip>
            </span>
            <span className="card-vehicle__txt--desc">{description}</span>
            <VehicleInfo label="VIN" value={vin} searchText={searchText} />
            <VehicleInfo
              label="REGO"
              value={registration_plate}
              searchText={searchText}
            />
          </div>
          <div className="card-vehicle__section relative">
            <div className="flex justify-between pr-4">
              {!!ppsr?.status && (
                <div
                  className={`${ppsrStatus.background} text-white text-sm px-1 rounded-4`}
                >
                  PPSR {ppsrStatus.label}
                </div>
              )}
            </div>
            {isGlobalSearch ? (
              <VehicleInfo
                label={data?.source?.label || "Customer Name"}
                value={data?.source?.value}
                searchText={searchText}
              />
            ) : (
              <VehicleInfo
                label={`${
                  (flow === FLOW_TYPE.RETAIL_DEALERSHIP || !isExternal) &&
                  !isValuationRequest
                    ? "Customer"
                    : "Dealership"
                } Name`}
                value={
                  (flow === FLOW_TYPE.RETAIL_DEALERSHIP || !isExternal) &&
                  !isValuationRequest
                    ? owner?.full_name || ""
                    : requester?.dealership?.name
                }
                searchText={searchText}
              />
            )}
            <VehicleInfo
              label="Staff Name"
              value={
                isGlobalSearch
                  ? data?.source?.staff_name
                  : isExternal || isValuationRequest
                  ? requester?.user?.name || created_by?.name
                  : created_by?.name
              }
              searchText={searchText}
            />
            {isDealershipSelectorVisible &&
              !isExternal &&
              !isValuationRequest &&
              !isGlobalSearch && (
                <VehicleInfo
                  label="Dealership"
                  value={dealership?.name || requester?.dealership?.name}
                />
              )}
            <span className="card-vehicle__txt--desc">
              {created_at || submitted_at || updated_at}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
